import React from "react";
import { isEmpty } from "lodash";
import "./style.scss";
import ClientSearch from "../client-search";
import "../../../images/search-background.png";

const Search = (props) => {
  const { products, initialProducts, categories, category, engine } = props;

  const placeholderText = "Search...";
  //console.log(products)
  return !isEmpty(props.products) ? (
    <>
    <div id="archive"></div>
    <div className="search-section py-10" style={{backgroundColor:'#E8D3D0'}}>
      {/* Search */}
      <ClientSearch
        products={products}
        initialProducts={initialProducts}
        categories={categories}
        category={category}
        engine={engine}
        placeholder={placeholderText}
      />
    </div>
    </>
  ) : (
    ""
  );
};

export default Search;
