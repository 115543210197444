import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { addToWishList, isProductInWishList } from "../../../utils/functions";
import Link from "gatsby-link";
import './style.scss'
import DataAddWish from '../../data-layer/add-to-wishlist'


const AddToWishList = ( { product } ) => {

	const [isInWishList, setInWishList] = useState( false );

	/* eslint-disable */
	useEffect( () => {
		const isItemInWishList = isProductInWishList( product.databaseId );
		setInWishList( isItemInWishList );
	}, [isInWishList] )

	/*if ( isEmpty( product ) ) {
		return null;
	} */

	const handleAddToWishList = () => {

		const productData = {
			node: {
				id: product.id,
				externalUrl: !isEmpty( product.externalUrl ) ? product.externalUrl : '',
				databaseId: product.databaseId,
				image: {
					id: !isEmpty( product.image.id ) ? product.image.id : '',
					altText: !isEmpty( product.image.altText ) ? product.image.altText : '',
					sourceUrl: !isEmpty( product.image.sourceUrl ) ? product.image.sourceUrl : '',
				},
				link: product.link.replace('https://admin.shroom4you.com',''),
				name: product.name,
				price: product.price,
				currencyPrice: product.currencyPrice,
				type: product.type ? product.type : product.nodeType
			}
		}
		//console.log(productData)
		addToWishList( productData );
		DataAddWish(product)
		setInWishList( true );
	}
	return (
		<div className="wishlist-container">
			{/*Add ot wishlist buttno*/ }
			{ !isInWishList ? (
				<button onClick={ handleAddToWishList } aria-label='add to wishlist' className="wishlist-btn -mt-2">
					<i className="heart-icon"><span className="heart"/></i>
				</button>
			) : (
				// View
				<Link to="/wishlist/">
					<button className="wishlist-btn woo-next-view-cart-btn">
						<i className="heart-icon"><span className="heart added"/></i>
					</button>
				</Link>
			) }
		</div>
	)
};

export default AddToWishList;
